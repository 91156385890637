import DiscordIcon from '@base/assets/img/svg/icon-discord.svg'
import GitHubIcon from '@base/assets/img/svg/icon-github.svg'
import TwitterIcon from '@base/assets/img/svg/icon-twitter.svg'
import MirrorIcon from '@base/assets/img/svg/icon-mirror.svg'

export const DEORDERBOOK_LINK_MAP = {
  LICENSE: 'https://github.com/DeOrderBook/v1-core/blob/main/LICENSE.md',
  TERMS: '/Terms_of_Service.pdf',
  PRIVACY: '/Privacy_Policy.pdf',
  DISCLAIMER: '/Disclaimer.pdf',
  DISCORD: 'https://discord.gg/f5kDvarwcX',
  GITHUB: 'https://www.github.com/DeOrderBook',
  TWITTER: 'https://twitter.com/DeOrderBook',
  MIRROR: 'https://mirror.xyz/0x2341BDd8a7047943586E948b1ca04e5E548Cd06D',
} as const

export const DOCUMENT_LINKS: { title: string; url: string }[] = [
  {
    title: 'Terms',
    url: DEORDERBOOK_LINK_MAP.TERMS,
  },
  {
    title: 'Privacy',
    url: DEORDERBOOK_LINK_MAP.PRIVACY,
  },
  {
    title: 'Disclaimer',
    url: DEORDERBOOK_LINK_MAP.DISCLAIMER,
  },
] as const

export const SOCIAL_LINKS: {
  title: string
  url: string
  icon: Component
}[] = [
  {
    title: 'Discord',
    url: DEORDERBOOK_LINK_MAP.DISCORD,
    icon: DiscordIcon,
  },
  {
    title: 'GitHub',
    url: DEORDERBOOK_LINK_MAP.GITHUB,
    icon: GitHubIcon,
  },
  {
    title: 'Twitter',
    url: DEORDERBOOK_LINK_MAP.TWITTER,
    icon: TwitterIcon,
  },
  {
    title: 'Mirror',
    url: DEORDERBOOK_LINK_MAP.MIRROR,
    icon: MirrorIcon,
  },
] as const
